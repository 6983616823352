<!-- eslint-disable vue/no-v-html -->
<template>
  <div :class="$style.root">
    <i
      :class="[$style.note, 'note', 'material-icons']"
      :style="{ color: note.color }"
    >text_snippet</i>
    <div
      :class="$style.text"
      :style="`--color: ${note.color}`"
    >
      <p :class="$style.title">{{ note.title }}</p>
      <div v-html="note.note.replace(/\n/g, '<br>')" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'HotelDirectoryHotelNote',
  props: {
    note: {
      type: Object,
      required: true
    },
    editable: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="stylus" module>
.note {
  transition transform .2s ease-in-out;
  font-size 14px !important;
  display block;

  &:hover {
    transform scale(1.1);
  }
}

.root {
  position relative;
  height: 14px;

  &:hover .text {
    display block;
  }
}

.title {
  font-size 14px;
  margin-bottom 5px;
  color rgb(84 111 122);
  font-weight bold;
}

.text {
  position absolute;
  bottom 100%;
  background #f5f5f5;
  padding 10px;
  padding-top: 5px;
  border-radius 2px;
  box-shadow 0px 2px 5px -1px rgba(0,0,0,0.2);
  color rgb(84 111 122);
  left 0;
  font-size 16px;
  display none;
  width 400px;
  max-height 400px;
  overflow auto;
  white-space pre-wrap;
}

.text:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background-color: var(--color);
}
</style>
