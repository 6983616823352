<template>
  <div :class="$style.editor">
    <div
      v-if="!activeSubModuleComponent"
      class="layout-column flex-center"
      :class="$style.loader"
    >
      <rbv-loading label="Loading, please wait..." />
    </div>
    <span v-else>
      <div :class="getContentStyles()">
        <component
          :is="activeSubModuleComponent"
          v-if="directory"
          v-bind="activeSubModule.props"
          :expanded="!open"
          :directory="directory"
          @preview="preview"
        />
        <div
          v-else
          class="layout-column flex-center"
          :class="$style.loader"
        >
          <rbv-loading label="Loading, please wait..." />
        </div>
      </div>
      <navigation
        :open="open"
        :directory="directory"
        @toggle="toggleNavigator"
      />
    </span>
  </div>
</template>

<script>
import Navigation from './navigation/HotelDirectoryEditorNavigation.vue';
import hotelDirectoryService from '../hotel-directory-service';
import RbvLoading from 'vRoot/_core/RbvLoading.vue';

export default {
    name: 'HotelDirectoryEditor',
    components: { Navigation, RbvLoading },
    props: {
        user: {
            type: Object,
            required: true,
        },
        accountId: {
            type: String,
            required: true,
        }
    },
    data(){
        return {
            open: true,
            directory: null,
        }
    },
    asyncComputed:{
        activeSubModule(){
            return hotelDirectoryService.initialize(this.user, this.accountId).then(module => {
                this.getDirectory();
                return module
            });
        }
    },
    computed: {
      activeSubModuleComponent() {
        return this.activeSubModule && this.activeSubModule.component;
      }
    },
    methods: {
        toggleNavigator(){
            this.open = !this.open;
        },
        getContentStyles(){
            return this.open? this.$style.content: this.$style.expanded;
        },
        preview(e){
            this.open = e;
        },
        getDirectory(){
            hotelDirectoryService.getDirectory().then(data => {
                this.directory = data;
            });
        }
    }
}
</script>

<style lang="stylus" module>
    .editor{
        position relative;
    }
    .content{
        height: calc(100vh - 60px);
        padding-left: 280px;
        transition padding .3s;
        overflow: hidden;
    }

    .expanded{
        composes content;
        padding-left 48px;
    }

    .loader{
        padding 100px;
    }
</style>
