import {isAnyCompetitiveBidOffer} from 'rfp/bid-manager/main/core/actions/action/CompetitiveBidIsOffer.service';
import get from 'lodash/get';

/**
 * Created by DejanK on 4/5/2017.
 *
 * All Bulk Bid Manager Actions
 */
export default BidManagerBulkBidActionsService;

BidManagerBulkBidActionsService.$inject = ['BidManagerActionsFactory'];
function BidManagerBulkBidActionsService(BidManagerActionsFactory) {
  const BUYER = 'BUYER', SUPPLIER = 'SUPPLIER';
  const $service = this;

  $service.getActions = getActions;

  function getActions(selectedBids, view) {
    const bidsReport = analyzeSelectedBids(selectedBids, view);

    return bidsReport.rfpType === 'HOTEL' ? getHotelRfpActions(bidsReport) : {};
  }

  function analyzeSelectedBids(bids, view){
    const statuses = new Set(), rfps = new Set(), chains = new Set(), bidsIds = [];
    let chainSupport = true;

    for(let i=0, l=bids.length; i<l; i++){
      const bid = bids[i];
      bidsIds.push(bid._id);
      statuses.add(bid.state.status);
      rfps.add(bid.rfp._id);
      chains.add(get(bid, 'supplier.company.chain.master._id'));
      chainSupport = chainSupport && bid.rfp.specifications.chainSupport;
    }

    return {
      bids,
      bidsIds,
      view,
      statuses: [...statuses],
      sharedRfp: rfps.size === 1 ? rfps.values().next().value : false,
      chainSupport,
      user: view.side,
      rfpType: view.rfpType,
      viewType: view.type,
      chain: chains.size === 1 ? chains.values().next().value : false
    };
  }

  function addAction(actionsArray, actionFn, selectedBidsReport){
    const action = actionFn(selectedBidsReport);
    if(action.isAvailable()) { actionsArray.push(action) }
  }

  function getHotelRfpActions(bidsReport) {
    if(isAnyCompetitiveBidOffer(bidsReport.bids)) {
      return getCompetitiveBidOfferActions(bidsReport);

    } else if (bidsReport.user === BUYER) {
      return getBuyerHotelRfpActions(bidsReport);

    } else if (bidsReport.user === SUPPLIER) {
      return getSupplierHotelRfpActions(bidsReport);

    } else return {};

    function getCompetitiveBidOfferActions() {
      const primaryActions = [], secondaryActions = [], otherActions = [], rfpActions = [];

      addAction(primaryActions, BidManagerActionsFactory.acceptCompetitiveBid, bidsReport);
      addAction(primaryActions, BidManagerActionsFactory.refuseCompetitiveBid, bidsReport);

      return {
        primary: primaryActions,
        secondary: secondaryActions,
        other: otherActions,
        rfp: rfpActions
      };
    }

    function getBuyerHotelRfpActions() {
      const primaryActions = [], secondaryActions = [], otherActions = [], rfpActions = [];

      addAction(primaryActions, BidManagerActionsFactory.sendBids(), bidsReport);
      addAction(primaryActions, BidManagerActionsFactory.setContacts(), bidsReport);
      addAction(primaryActions, BidManagerActionsFactory.sendFinalAgreements, bidsReport);
      addAction(primaryActions, BidManagerActionsFactory.resendFinalAgreements, bidsReport);

      addAction(secondaryActions, BidManagerActionsFactory.requestRateLoading, bidsReport);
      addAction(secondaryActions, BidManagerActionsFactory.markRatesAsLoaded, bidsReport);
      addAction(secondaryActions, BidManagerActionsFactory.markRatesAsNotLoaded, bidsReport);
      addAction(secondaryActions, BidManagerActionsFactory.uploadNegotiations, bidsReport);
      addAction(secondaryActions, BidManagerActionsFactory.reUploadNegotiations, bidsReport);
      addAction(secondaryActions, BidManagerActionsFactory.resendNegotiationRequests, bidsReport);
      addAction(secondaryActions, BidManagerActionsFactory.setFinalAgreementPending, bidsReport);
      addAction(secondaryActions, BidManagerActionsFactory.sendNoThankYouLetters, bidsReport);
      addAction(secondaryActions, BidManagerActionsFactory.setNoThankYouPending, bidsReport);
      addAction(secondaryActions, BidManagerActionsFactory.sendTotelDirectory, bidsReport);
      addAction(secondaryActions, BidManagerActionsFactory.deleteBids, bidsReport);
      addAction(secondaryActions, BidManagerActionsFactory.resetBids, bidsReport);
      addAction(primaryActions, BidManagerActionsFactory.uploadResponses({side:'BUYER'}), bidsReport);

      addAction(rfpActions, BidManagerActionsFactory.editRfp, bidsReport);
      addAction(otherActions, BidManagerActionsFactory.changeTravelDestination, bidsReport);
      addAction(otherActions, BidManagerActionsFactory.sendReminder, bidsReport);
      addAction(otherActions, BidManagerActionsFactory.setBidsRateAccessCode, bidsReport);

      return {
        primary: primaryActions,
        secondary: secondaryActions,
        other: otherActions,
        rfp: rfpActions
      };
    }

    function getSupplierHotelRfpActions() {
      const primaryActions = [], secondaryActions = [], otherActions = [], rfpActions = [];

      addAction(primaryActions, BidManagerActionsFactory.sendResponses, bidsReport);
      addAction(primaryActions, BidManagerActionsFactory.removeDeletedBids, bidsReport);
      addAction(primaryActions, BidManagerActionsFactory.removeRefusedCompetitiveBid, bidsReport);
      addAction(primaryActions, BidManagerActionsFactory.sendCompetitiveBidResponse, bidsReport);

      addAction(secondaryActions, BidManagerActionsFactory.uploadResponses(), bidsReport);
      addAction(secondaryActions, BidManagerActionsFactory.uploadNegotiations, bidsReport);
      addAction(secondaryActions, BidManagerActionsFactory.reUploadNegotiations, bidsReport);
      addAction(secondaryActions, BidManagerActionsFactory.sendNotInterestedInBulk, bidsReport);
      addAction(secondaryActions, BidManagerActionsFactory.markRatesAsLoaded, bidsReport);
      addAction(secondaryActions, BidManagerActionsFactory.markRatesAsNotLoaded, bidsReport);

      return {
        primary: primaryActions,
        secondary: secondaryActions,
        other: otherActions,
        rfp: rfpActions
      };
    }
  }
}
