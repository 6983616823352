var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row",class:( _obj = {
      selected: _vm.isManagingNotes ? _vm.row.added : _vm.added
    }, _obj[_vm.$style.notInDirectory] = _vm.isManagingNotes ? !_vm.row.added : !_vm.added, _obj[_vm.$style.row] = true, _obj )},[(!_vm.updating)?[(_vm.canAdd)?_c('check-box',{staticClass:"cell checkboxCell dark-icon",attrs:{"value":_vm.added,"check-box-value":true},on:{"input":_vm.toggle}}):_c('div',{staticClass:"cell checkboxCell",class:_vm.$style.invalidIcon,on:{"click":_vm.openFinalAgreement}},[_c('i',{staticClass:"material-icons"},[_vm._v("warning")]),_c('div',{staticClass:"rbTooltip simple",attrs:{"tooltip":"placement:top-start"}},[_vm._v(" We have detected that this final agreement contains rates and/or seasons that are invalid. "),_c('br'),_vm._v(" Thus it can not be added to the hotel directory. Click here to view this bid in order to fix this error ")])])]:_c('rbv-loading',{staticClass:"cell checkboxCell",class:_vm.$style.loading,attrs:{"rb-class":"medium"}}),_vm._l((_vm.tableColumns),function(column,i){
    var _obj;
return _c('div',{key:column,staticClass:"cell",style:(_vm.getColumnStyles(i, column))},[(_vm.is(column, 'distanceMi'))?_c('div',[_vm._v(" "+_vm._s(_vm._f("distance")(_vm.getColumnValue(column)))+" Mi ")]):(_vm.isAmenity(column))?_c('div',{class:_vm.row.amenities.indexOf(column) > -1
            ? _vm.$style.amenity
            : _vm.$style.amenityAbsent},[_c('i',{staticClass:"material-icons"},[_vm._v(_vm._s(_vm.amenity(column).icon))]),_c('span',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.row.amenities.indexOf(column) > -1 ? column : "N/A")+" ")])]):(_vm.is(column, 'rates'))?_c('div',{class:_vm.$style.clickable,on:{"click":_vm.showRates}},[_c('span',[(_vm.rateShown)?[_vm._v(_vm._s(_vm.rateShown))]:_vm._e()],2)]):(_vm.is(column, 'code'))?_c('div',[_c('span',[(_vm.propertyCodeShown)?[_vm._v(_vm._s(_vm.propertyCodeShown))]:_vm._e()],2)]):(_vm.is(column, 'rating'))?_c('rb-rating',{class:( _obj = {}, _obj[_vm.$style.hotelType] = true, _obj.dark = !_vm.added, _obj ),attrs:{"rate":_vm.getColumnValue(column)}}):(_vm.is(column, 'name'))?_c('div',[_vm._v(" "+_vm._s(_vm.getColumnValue(column))+" "),(_vm.isManagingNotes)?_c('div',{class:_vm.$style.notes},_vm._l((_vm.row.notes || []),function(note){return _c('i',{key:note.id,class:[_vm.$style.note, 'material-icons'],style:({ color: note.color })},[_vm._v("text_snippet")])}),0):_vm._e()]):_c('div',[_vm._v(" "+_vm._s(_vm.getColumnValue(column))+" ")])],1)}),_c('div',{staticClass:"cell checkboxCell plus",on:{"click":_vm.openRow}},[_c('i',{staticClass:"material-icons"},[_vm._v(_vm._s(!_vm.open ? "add" : "remove"))])])],2),_c('div',{staticClass:"wrapped-cells",class:{ open: _vm.open }},[_vm._l((_vm.wrappedColumns),function(column){return [(_vm.is(column, 'distanceMi'))?_c('div',{key:column,staticClass:"wrapped-cell"},[_c('div',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.getColumnLabel(column).text)+" ")]),_c('div',{key:column,staticClass:"value"},[_vm._v(" "+_vm._s(_vm._f("distance")(_vm.getColumnValue(column)))+" Mi ")])]):(_vm.isAmenity(column))?_c('div',{key:column + '-amenity',staticClass:"wrapped-cell"},[_c('div',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.amenity(column).name)+" ")]),_c('div',{key:_vm.amenity.abbr,staticClass:"value"},[_c('div',{class:_vm.row.amenities.indexOf(column) > -1
                ? _vm.$style.amenity
                : _vm.$style.amenityAbsent},[_c('i',{staticClass:"material-icons"},[_vm._v(_vm._s(_vm.amenity(column).icon))]),_c('span',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.row.amenities.indexOf(column) > -1 ? column : "N/A")+" ")])])])]):(_vm.is(column, 'rating'))?_c('div',{key:column + '-rating',staticClass:"wrapped-cell"},[_c('div',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.getColumnLabel(column).text)+" ")]),_c('rb-rating',{key:column,staticClass:"value",class:_vm.$style.hotelType,attrs:{"rate":_vm.getColumnValue(column)}})],1):(_vm.is(column, 'rates'))?_c('div',{key:column + '-rates',staticClass:"wrapped-cell",class:_vm.$style.wrappedRate},[_c('div',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.shownRateLabel)+" ")]),_c('div',{staticClass:"value"},[_c('div',{class:_vm.$style.rate,on:{"click":_vm.showRates}},[(_vm.rateShown)?[_vm._v(" "+_vm._s(_vm.rateShown)+" ")]:_vm._e()],2)])]):(_vm.is(column, 'code'))?_c('div',{key:column + '-code',staticClass:"wrapped-cell"},[_c('div',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.propertyCodeLabel)+" ")]),_c('div',{staticClass:"value"},[_c('div',[(_vm.propertyCodeShown)?[_vm._v(" "+_vm._s(_vm.propertyCodeShown)+" ")]:_vm._e()],2)])]):_c('div',{key:column + '-other',staticClass:"wrapped-cell"},[_c('div',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.getColumnLabel(column).text)+" ")]),_c('div',{key:column,staticClass:"value"},[_vm._v(" "+_vm._s(_vm.getColumnValue(column))+" ")])])]})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }